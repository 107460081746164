.feeback_container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
.feedback_box {
  width: calc(33% - 0.5rem);
  background-color: var(--white);
  padding: 2rem 2.5rem;
  border-radius: 2rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.feedback {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: flex-start;
}
.feedback_items {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
}
.feedback_item p {
  font-size: 1.2rem;
  font-weight: 800;
  text-transform: uppercase;
  color: var(--grey);

  display: flex;
  gap: 1rem;
  align-items: center;
}
.feedback_item span {
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
  color: var(--grey);
  border: 1px solid var(--grey);
  padding: 0.3rem 1rem;
  border-radius: 3rem;
}
.feedback_item h3 {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--grey);
}
.feedback_order {
  font-family: 'Poppins', sans-serif;
  font-size: 1.1rem;
  font-weight: 800;
  text-transform: uppercase;
  background-color: var(--primary-color-light);
  padding: 0.5rem 1rem;
  color: var(--primary-color);
  border-radius: 3rem;
  white-space: nowrap;
}
.feedback_actons {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--light-grey);
}
.feedback_default {
  align-self: 'flex-end';
  font-family: 'Poppins', sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  background-color: var(--light-grey);
  padding: 0.5rem 1.2rem;
  color: var(--grey);
  border-radius: 3rem;
  white-space: nowrap;
}

/* Patient Feedback styles */
.patient_feedback {
  width: 40rem;
  background-color: var(--white);
  padding: 1.5rem 2rem;
  border-radius: 2rem;
  font-family: 'Poppins', sans-serif;
}
.patient_feedback img {
  height: 3rem;
  background-color: var(--light-grey);
  border-radius: 50%;
  padding: 0.5rem;
}
.patient_feedback h4 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--grey);
}
.patient_feedback h5 {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--primary-color);
}
.patient_feedback h6 {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--grey);
}
.patient_feedback p {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--grey);
}
