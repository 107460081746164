.container {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}
.label {
  font-size: 1.3rem;
  color: var(--grey);
}
.inputBox {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--white);
  border: 1px solid #d0d0d0;
  border-radius: 1rem;
  padding: 0 1rem;
  /* padding-right: 2.5rem; */
  transition: all 0.4s;
}
.textInput__input {
  width: 90%;
  padding: 0.8rem 0.5rem;
  background-color: transparent;
  outline: none;
  border: none;
  font-family: inherit;
  font-weight: 600;
  color: var(--grey);
}
.inputBox__icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--grey);
  transition: all 0.4s;
}
.textInput__input:-webkit-autofill,
.textInput__input:-webkit-autofill:hover,
.textInput__input:-webkit-autofill:focus,
.textInput__input:-webkit-autofill:active {
  font-weight: 600;
  border: none;
  -webkit-text-fill-color: var(--grey);
  -webkit-box-shadow: 0 0 0px 1000px var(--white) inset;
  transition: background-color 5000s ease-in-out 0s;
}
.errorText {
  /* margin-left: 3.5rem; */
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--red);
}
.inputBox__icon_check {
  height: 2rem;
  width: 2rem;
  fill: var(--green);
  /* position: absolute;
  right: 0.5rem; */
}
.inputBox__toggle_password {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.inputBox__toggle_password svg {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--grey);
}
