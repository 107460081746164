.model_container {
	display: flex;
	flex-direction: column;
	background-color: #f2f8ff;
	gap: 2rem;
	margin-bottom: 2rem;
}

.generate_ad_button {
	align-self: flex-end;
	width: fit-content;
	padding: 0.8rem 1.2rem;
	font-size: 1.2rem;
	color: white;
	background-color: var(--primary-color);
	border: 1px solid var(--primary-color);
	border-radius: 1rem;
	cursor: pointer;
	transition: all 0.2s;
}

.generate_ad_button:hover {
	color: var(--primary-color);
	background-color: #f2f8ff;
}

.reset_rules_button {
	align-self: flex-end;
	margin-bottom: 1rem;
	width: fit-content;
	padding: 0.8rem 1.2rem;
	font-size: 1.2rem;
	color: var(--primary-color);
	background-color: #f2f8ff;
	border: 1px solid var(--primary-color);
	border-radius: 1rem;
	cursor: pointer;
	transition: all 0.2s;
}

.reset_rules_button:hover {
	color: white;
	background-color: var(--primary-color);
}

.delete_modal_container {
	margin: 0 2rem;
	display: flex;
	flex-direction: column;
	background-color: #f2f8ff;
	gap: 1rem;
	margin-bottom: 1rem;
}

.delete_modal_button_container {
	margin-top: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.delete_modal_warning {
	font-size: 1.5rem;
}

.delete_modal_delete_button {
	width: fit-content;
	padding: 0.5rem 1.2rem;
	display: flex;
	align-items: center;
	font-size: 1.2rem;
	color: var(--primary-color);
	background-color: #f2f8ff;
	border: 1px solid var(--primary-color);
	border-radius: 1rem;
	cursor: pointer;
	transition: all 0.2s;
}

.delete_modal_delete_button:hover {
	color: white;
	background-color: var(--primary-color);
}

.rules_container {
	min-height: 58vh;
	padding: 1rem 0;
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
	overflow-y: auto;
}

.rule_container {
	padding: 2rem;
	background-color: white;
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	border-radius: 2rem;
}

.rule_row {
	display: grid;
	grid-template-columns: 0.8fr 0.4fr 1fr;
	column-gap: 1rem;
	align-items: flex-start;
}

.advertise_select {
	height: 3.5rem;
	padding: 0.5rem;
	font-size: 1.2rem;
	border: 1px solid rgb(209, 209, 209);
	border-radius: 1rem;
	transition: all 0.5s ease-out;
}

.add_rule_button {
	width: fit-content;
	padding: 0 1rem;
	text-align: center;
	color: #0f407a;
	border: 1px solid #0f407a;
	border-radius: 5px;
	align-items: center;
	transition: 0.4s all;
}

.add_rule_button:hover {
	cursor: pointer;
	border: 1px solid #0f407a;
	background-color: #0f407a;
	color: white;
}

.remove_button {
	align-self: flex-end;
	width: fit-content;
	color: red;
	border: 1px solid red;
	border-radius: 5px;
	text-align: center;
	align-items: center;
	padding-left: 6px;
	padding-right: 6px;
	transition: 0.4s all;
}

.remove_button:hover {
	cursor: pointer;
	border: 1px solid red;
	background-color: red;
	color: white;
}

.rule_card_container_heading {
	padding: 0.5rem 1rem;
	font-size: 1.5rem;
}

.rule_card_container {
	max-height: 45%;
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	column-gap: 1rem;
	row-gap: 2rem;
	overflow-y: scroll;
	box-sizing: border-box;
}

.rule_card_container_bottom {
	position: sticky;
	bottom: 0;
	height: 20px;
	width: 100%;
	background: linear-gradient(
		0deg,
		rgba(241, 241, 241, 1) 0%,
		rgba(241, 241, 241, 0) 100%
	);
	z-index: 2;
}

.rule_card_options {
	padding-right: 1rem;
	display: flex;
	justify-content: flex-end;
	gap: 0.5rem;
}

.rule_card {
	width: 31%;
	padding: 1.5rem 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	background-color: white;
	border-radius: 2rem;
}

.rule_card_upper_content {
	padding: 0.5rem 1rem;
	display: flex;
	gap: 0.5rem;
}

.rule_card_upper_content_chip1 {
	padding: 0.5rem 1.5rem;
	width: fit-content;
	display: flex;
	align-items: center;
	font-size: 1.2rem;
	color: var(--white);
	background-color: var(--primary-color);
	border-radius: 2rem;
	text-transform: capitalize;
}

.rule_card_upper_content_chip2 {
	padding: 0.5rem 1.2rem;
	width: fit-content;
	display: flex;
	align-items: center;
	font-size: 1rem;
	background-color: #4ed28b;
	border-radius: 2rem;
	color: var(--white);
	text-transform: capitalize;
}

.rule_card_lower_content {
	height: 80px;
	overflow-y: scroll;
	padding: 1rem;
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	border: 1px solid rgba(12, 8, 8, 0.103);
	border-radius: 2rem;
}

.rule_card_lower_content_chip {
	height: fit-content;
	padding: 0.3rem 1rem;
	color: #0f407a;
	background-color: #e9f3fa;
	border-radius: 2rem;
}

.generated_ad_container {
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
}

.generated_ad_card_container {
	margin-top: 1rem;
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	column-gap: 1rem;
	row-gap: 2rem;
}

.generated_ad_card {
	width: 31%;
	padding: 1.5rem 1rem;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	background-color: white;
	border-radius: 2rem;
	transition: all 0.2s;
	position: relative;
	overflow: hidden;
}

.generated_ad_card > img {
	border: 1px solid rgba(12, 8, 8, 0.103);
	border-radius: 1.5rem;
}

.generated_ad_title {
	padding: 0 1rem;
	font-size: 1.5rem;
}

@media (max-width: 1024px) {
	.rule_card {
		width: 49%;
	}
	.generated_ad_card {
		width: 49%;
	}
}

@media (max-width: 820px) {
	.rule_card {
		width: 100%;
	}
	.generated_ad_card {
		width: 100%;
	}
}
