.headingButtons {
  display: flex;
  /* gap: 1rem; */
  align-items: center;

  /* padding: 1rem 1rem; */
  margin: 1rem 2rem;
  border-radius: 2rem;
  overflow: hidden;
  width: fit-content;
  background-color: var(--white);
}
.headingButton {
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 600;
  outline: none;
  border: none;
  border-left: 1px solid var(--white);
  border-right: 1px solid var(--white);
  background-color: var(--light-grey);
  padding: 1.5rem 2rem;
  cursor: pointer;
  transition: all 0.4s;
}
.headingButton:hover {
  background-color: var(--white);
  color: var(--primary-color);
}
.headingButton:disabled {
  background-color: var(--primary-color);
  color: var(--white);
  cursor: not-allowed;
}
.headingButtonActive {
  background-color: var(--primary-color);
  color: var(--white);
}

.form_content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem 2rem;
  max-height: 68vh;
  overflow: auto;
}
.form_heading {
  background-color: var(--primary-color-light);
  padding: 2rem 2rem;
  border-radius: 2rem;

  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.form_heading_content {
  font-size: 1.2rem;
  font-weight: 600;
  /* line-height: 1.3rem; */
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.form_heading_content span {
  background-color: var(--light-orange);
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  border-radius: 0.5rem;
  min-width: 10rem;
}
.form_heading_content span input {
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  width: 12rem;
}
.form_heading_content span select {
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  width: 12rem;
}
.form_container {
  background-color: var(--white);
  padding: 2rem 2rem;
  border-radius: 2rem;
}
.form_container_heading {
  font-size: 1.3rem;
  font-weight: 600;
}
.form_relations {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.form_relation {
  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: repeat(3, 1fr); */
  gap: 2rem;

  background-color: var(--bg-color);
  padding: 1rem 2rem;
  border-radius: 1rem;
}
.form_relation_text {
  font-size: 1.2rem;
  font-weight: 500;

  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.form_relation_text p {
  width: 8rem;
}
.form_relation_text span {
  background-color: var(--white);
  width: 15rem;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  border-radius: 0.5rem;
}
.form_relation_text span input {
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: inherit;
}
.form_relation_text span select {
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  width: 100%;
}

.form_numbers {
  display: flex;
  /* grid-template-columns: repeat(3, 1fr); */
  flex-wrap: wrap;
  gap: 2rem;

  background-color: var(--bg-color);
  padding: 1rem 2rem;
  border-radius: 1rem;
}
.form_numbers_text {
  font-size: 1.2rem;
  font-weight: 500;

  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.form_numbers_text span {
  background-color: var(--white);
  width: 15rem;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  border-radius: 0.5rem;
}
.form_numbers_text span input {
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: inherit;
}
.form_numbers_text span select {
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  width: 100%;
}

.form_container_grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.form_check_box {
  margin: 1rem 0;
  display: flex;
  gap: 1rem;
  align-items: center;
  /* justify-content: space-between; */
}
.form_check_box p {
  font-size: 1.2rem;
  font-weight: 600;
}
