.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4vw;
}

.container img {
  width: 38vw;
}

.container h1 {
  font-size: 2vw;
  color: #0f407a;
}

.container h3 {
  font-size: 1vw;
  color: #3f3d56;
}

.containers {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4vw;
}

.containers img {
  width: 34vw;
}

.containers h1 {
  font-size: 2vw;
  color: #0f407a;
}

.containers h3 {
  font-size: 1vw;
  color: #3f3d56;
}

@media (max-width: 1023px) {
  .container {
    flex-direction: column;
    text-align: center;
  }

  .containers {
    flex-direction: column;
    text-align: center;
    gap: 5vh;
    margin-top: 5vh;
  }
}
