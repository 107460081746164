.container {
  display: flex;
  justify-content: space-around;
}

.step {
  display: flex;
  align-items: center;
  padding-left: 15px;
  grid-gap: 1rem;
  gap: 1rem;
  flex-direction: column;
  /* width: 100px; */
  text-align: center;
}

.bullet {
  position: relative;
  width: 3rem;
  height: 3rem;
  background-color: #c4c4c4;
  border: 1px solid #c4c4c4;
  color: #000;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem; 
}

.step p {
  font-size: 1.2rem;
  text-transform: capitalize;
  font-weight: 600; 
}

.bullet::before,
.bullet::after {
  position: absolute;
  content: '';
  bottom: 11px;
  right: 20px;
  height: 6px;
  width: 90px;
  background: #c4c4c4;
  z-index: -1;
}

.step:first-child .bullet::before,
.step:first-child .bullet::after {
  display: none;
}

.Complete p{ 
  font-size: 1.2rem;
  text-transform: capitalize;
  color: var(--primary-color);
  font-weight: 700; 
}

.Complete .bullet { 
  width: 3rem;
  height: 3rem;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color:#fff;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  border: 1px solid #0000;
}

.Complete .bullet::after {
  position: absolute;
  content: '';
  bottom: 11px;
  right: 20px;
  height: 6px;
  width: 90px;
  background: #000;
  transform: scaleX(0);
  transform-origin: left;
  animation: animate 0.8s linear forwards;
}

@keyframes animate {
  100% {
    transform: scaleX(1)
  }
}