.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 70vh;
}
.container svg {
  height: 30rem;
  width: 30rem;
  fill: var(--grey);
}
.container p {
  font-size: 2rem;
  font-weight: 600;
  color: var(--grey);
}
