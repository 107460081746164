.tableContainer {
  background-color: var(--white);
  border-radius: 2rem;
  overflow-y: auto;
  overflow-x: auto;
}
.table {
  position: relative;
  background-color: var(--white);
  border-collapse: collapse;
  width: 100%;
  padding: 2rem;
  font-size: 1.2rem;
}

th {
  position: sticky;
  top: 0;
  z-index: 498;
}

.table tr th {
  background-color: var(--white);
  color: var(--black);
  white-space: nowrap;
  padding: 1rem 0.5rem;
  text-align: left;
  font-size: 1.7rem;
  font-weight: 600;
}

.table tr td {
  padding: 0.5rem;
  color: var(--grey);
  white-space: nowrap;
}
.table tr {
  transition: all 0.8s;
  border-bottom: 1px solid var(--light-grey);
}
.table__row_address span {
  display: block;
  font-size: 1.1rem;
}
.table tr:hover {
  background-color: var(--light-orange);
}
.table__profile_container {
  display: flex;
  justify-content: flex-end;
}
.table__profile_image {
  height: 3rem;
  align-self: flex-end;
}
.table__row_title {
  font-size: 1.5rem;
  font-weight: 700;
}
.table__row_title span {
  margin-left: 1rem;
  font-size: 1rem;
  font-weight: 700;
  border: 1px solid var(--primary-color);
  text-transform: uppercase;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
}
.table__row_title button {
  font-family: inherit;
  background-color: transparent;
  color: currentColor;
  border: none;
  cursor: pointer;
  font-size: inherit;
  font-weight: inherit;
  border-bottom: 1px solid transparent;
  transition: all 0.4s;
}
.table__row_title button:hover {
  border-bottom: 1px solid var(--grey);
}
.table__call_btn {
  display: block;
}
.table__status,
.table__status_deactive {
  border-radius: 1rem;
  padding: 0.3rem 0.5rem;
  width: 8rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}
.table__status {
  border: 1px solid var(--light-grey);
}
.table__status span,
.table__status_deactive span {
  font-size: 3rem;
  line-height: 0.9rem;
}
.table__status span {
  color: var(--green);
}
.table__status_deactive {
  border: 1px solid var(--light-grey);
  color: var(--light-grey);
}
.table__status_deactive span {
  color: var(--red);
}

.table__buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.table__button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.4s;
  position: relative;
  font-family: inherit;
}
.table__button_tooltip {
  position: absolute;
  top: -1.7rem;
  right: 0;
  transform: translateX(40%);
  z-index: 10000;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
  background-color: var(--black);
  color: var(--white);
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  display: none;
}
.table__button_icon {
  height: 2rem;
  width: 2rem;
  fill: var(--grey);
  transition: all 0.4s;
}
/* .table__button:hover {
  transform: scale(1.02);
} */
.table__button:hover .table__button_tooltip {
  display: block;
}
.table__button:hover .table__button_icon {
  fill: var(--primary-color);
}

.table__button_delete {
  background-color: var(--red);
  border: 1px solid var(--red);
  border-radius: 50%;
  padding: 0.3rem;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.4s;

  position: relative;
  font-family: inherit;
}
.table__button_delete_icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--white);
  transition: all 0.4s;
}
.table__button_delete:hover {
  background-color: transparent;
}
.table__button_delete:hover .table__button_tooltip {
  display: block;
}
.table__button_delete:hover .table__button_delete_icon {
  fill: var(--red);
}

.table__selectBox {
  background-color: var(--light-grey);
  border-radius: 1rem;
  padding-right: 1rem;
  width: 15rem;
}
.table__select {
  font-family: inherit;
  border: none;
  background-color: transparent;
  padding: 0.5rem 1rem;
  outline: none;
}
.table tr td.loaderRow {
  text-align: center;
  font-size: 1.5rem;
}
