.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 400px));
  /* grid-auto-rows: 330px; */
  justify-content: center;
  column-gap: 1rem;
  row-gap: 1rem;
}

.input_msg {
  font-family: Gilroy-light;
  font-weight: bold;
  margin-top: 1rem;
}

.card {
  background-color: #fefefe;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 2px solid rgb(5, 117, 230, 1);
  border-image-slice: 1;
  padding: 10px 0;
}

.card2 {
  background-color: #fefefe;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  border: 2px solid rgb(5, 117, 230, 1);
  padding: 10px 0 0 0;
  border-radius: 5px;
}

.video-avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 147px;
  max-width: 400px;
  max-height: 100%;
}

.video-active {
  width: 100%;
  background-color: #fefefe;
  height: 300px;
}

.iconsDiv {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 0.5rem;
  /* background-color: aqua; */
}

.iconsDiv > div {
  --size: 2.8rem;
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
}

.icons {
  background-color: #fdfdfd;
  border-radius: 50%;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.34);
  transition: box-shadow 0.3s ease-in-out;
  font-size: 1.4rem;
}

.icons > * {
  width: 20px;
  height: 20px;
}

.icons:active {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.19);
}

.icons:hover {
  cursor: pointer;
  opacity: 0.8;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -16px, 0);
  }
}

.bouncing-loader {
  display: flex;
  justify-content: center;
  margin: 5rem auto;
  border: 0px solid black;
}

.bouncing-loader > div {
  width: 20px;
  height: 20px;
  margin: 3rem 0.2rem;
  background: var(--primary-color);
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

.img_illus {
  height: 15rem;
}

.chat_img_div {
  display: grid;
  place-items: center;
}

.msg_flex > div {
  background-color: #fdfdfd;
  border: 1px solid #bebebe;
  border-radius: 4px;
  padding: 0.4rem;
  margin-top: 0.4rem;
}

.msg_flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  max-height: 250px;
  overflow-y: scroll;
}

.msg_flex::-webkit-scrollbar {
  display: none;
}

.msg_sent {
  align-self: flex-end;
}

#no_border {
  border: none;
}

.main_video_container {
  display: grid;
  gap: 1rem;
  grid-template-columns: 80% 20%;
}
.video_chat_text {
  grid-column: 2 / -1;

  display: flex;
  gap: 1rem;
  flex-direction: column;

  /* background-color: var(--white);
  border-radius: 1rem;
  padding: 1rem 2rem;
  height: 70vh;
  overflow: auto; */
}
.video_chat_chat_heading {
  font-size: 1.5rem;
  text-align: center;

  background-color: var(--white);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  height: 3.5rem;
}
.video_chat_chats {
  background-color: var(--white);
  border-radius: 1rem;
  padding: 1rem 1rem;
}
.video_chat_chats_scroll {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: calc(24.5vh - 4.8rem);
  overflow: auto;
}
.video_chat_chats_transcriptText {
  font-size: 1.3rem;
}
.video_chat_chats_transcriptUser {
  align-self: flex-end;
  font-size: 1.2rem;
  background-color: var(--primary-color);
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  color: var(--light-grey);
}
.video_chat_chat_btns {
  margin-bottom: 2rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}
.video_chat_chat_btn {
  cursor: pointer;
  font-family: inherit;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: var(--white);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: all 0.4s;
}
.video_chat_chat_btn:hover {
  background-color: transparent;
  color: var(--primary-color);
}
.video_chat_chat_text {
  font-size: 1.2rem;
  text-align: center;
}

.main_video_frame {
  grid-column: 1 / 2;
  display: flex;
  flex-direction: row-reverse;
  width: 75vw;
  height: 80vh;
  overflow: hidden;
  /* min-height: 70vh; */
  background-color: var(--light-grey);
  /* padding: 1rem; */
  border-radius: 2rem;
  position: relative;
  justify-content: center;
  align-items: center;
}

.main_video_frame > .local_video {
  position: absolute;
  top: 2rem;
  right: 2rem;
  min-width: 15vw;
  height: 15vh;
  /* overflow: hidden; */
  border-radius: 2rem;
  background-color: var(--white);
}

.main_video_frame > .local_video_player {
  width: 75vw;
  border-radius: 2rem;
  /* height: 100%; */
  /* height: 70vh; */
}

.main_video_frame > .local_video > .local_video_player {
  /* min-width: 100%; */
  /* height: 100%; */
  width: 15vw;
  border-radius: 2rem;
  background-color: var(--white);
}

.main_video_frame > .remote_user_name {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 1rem 2rem;
  border-radius: 1.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.remote_user_name p {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--white);
}
.main_video_actions {
  position: absolute;
  bottom: 1.5rem;
}
.main_video_action_btns {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}
.shareScreenStatus {
  margin-top: 1rem;
  text-align: center;
  color: var(--white);
  font-size: 1.1rem;
  font-weight: 600;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.shareScreenStatus button {
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.4s;
}
.shareScreenStatus button:hover {
  color: var(--light-orange);
}
.icon_circle {
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 1rem;
  border-radius: 2rem;
  backdrop-filter: blur(4px);
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  transition: all 0.4s;
}
.icon_red {
  background-color: var(--red);
  padding: 1.5rem;
}

.icon_circle:hover {
  transform: scale(1.1);
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.icon_circle > svg {
  height: 2rem;
  width: 2rem;
  fill: var(--white);
}

.icon_circle_white {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--white);
}

/* @media only screen and (max-width: 600px) {
    .grid {
      grid-template-columns: repeat(auto-fit, minmax(325px, 425px));
    }
    #video1 {
      grid-row: 2/3;
    }
    #video2 {
      grid-row: 1/2;
    }
    #video3 {
      grid-row: 1/2;
    }
  } */
@media only screen and (max-width: 360px) {
  .iconsDiv > div {
    --size: 2.5rem;
  }

  .icons {
    font-size: 1rem;
  }

  .icons > * {
    width: 15px;
    height: 15px;
  }
}
