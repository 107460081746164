.mainContent {
  grid-column: 2 / -1;
  overflow: hidden;
  transition: all 0.5s ease;
  margin: 1.5rem 0;
  margin-right: 1.5rem;
  background-color: #f1f1f1;
  /* box-shadow: 0.5rem 0.5rem 2rem 2px #09baa51a; */
  border-radius: 2rem;
  position: absolute;
  left: 19rem;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 50%;
}
.content {
  margin-top: 1.5rem;
  height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  padding-top: 1rem;
}

/* Header css */
.header {
  margin-top: 3rem;
  padding: 0 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__left {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.header__title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.4;
}
.headerResult {
  font-size: 1.2rem;
  line-height: 1;
  color: var(--grey);
  font-weight: 600;
}
.header__button {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  color: var(--white);
  font-family: inherit;
  font-weight: 500;
  font-size: 1.1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  transition: all 0.4s;
}
.header__button_icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--white);
  transition: all 0.4s;
}
.header__button:hover {
  background-color: transparent;
  color: var(--primary-color);
}
.header__button:hover .header__button_icon {
  fill: var(--primary-color);
}
.header__right {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.header__link {
  text-decoration: none;
  color: var(--grey);
  font-size: 1.2rem;
  font-weight: 700;
  transition: all 0.4s;
}
.header__link:hover {
  /* transform: translateY(-.1rem); */
  color: var(--primary-color);
}
.header__logout {
  background-color: transparent;
  border: none;
  color: var(--grey);
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 700;
  cursor: pointer;

  display: flex;
  gap: 0.5rem;
  align-items: center;
  transition: all 0.4s;
}
.header__logout_icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--grey);
  transition: all 0.4s;
}
.header__logout:hover {
  color: var(--primary-color);
}
.header__logout:hover .header__logout_icon {
  fill: var(--primary-color);
}

.headerTextInput {
  background-color: var(--white);
  padding: 0 1.5rem;
  border: 1px solid var(--light-grey);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.4s;
}
.headerTextInput_icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--light-grey);
  transition: all 0.4s;
}
.headerTextBox {
  padding: 1rem;
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 700;
  height: 100%;
}
.headerTextBox::-webkit-input-placeholder {
  color: var(--light-grey);
}


/* Create a custom checkbox */
.checkbox_container .checkmark { 
  height: 20px;
  width: 20px;
  background-color: #eee;
}