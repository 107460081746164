.container {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}
.form_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(50% - 1rem);
  background-color: var(--white);
  padding: 2rem;
  border-radius: 2rem;
}
.heading {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.subheading {
  font-size: 1.3rem;
  font-weight: 600;
}
.iframe_container {
  width: 100%;
  margin-bottom: 1.5rem;
}
.iframe_container iframe {
  width: 100%;
  height: 30rem;
  border-radius: 2rem;
}
.iframe_container div {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  font-size: 1.2rem;
  font-weight: 600;
}
.iframe_container div button {
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.4s;
}
.iframe_container div button:hover {
  color: var(--primary-color);
}
