.form_input {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  background-color: #ffffff;
  padding: 1.5rem 2rem;
  padding-bottom: 2.5rem;
  border-radius: 2rem;
  margin-bottom: 2rem;
}
.form_input__label {
  color: #8b8b8b;
  font-size: 1.2rem;
}
.form_input__container {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  padding-left: 1rem;
  width: 25rem;
}
.form_input__icon {
  height: 2rem;
  width: 2rem;
  fill: #e7e7ea;
}
.form_input__box {
  font-family: inherit;
  background-color: transparent;
  color: #e7e7ea;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
}
.form_input__box::-webkit-input-placeholder {
  color: #8b8b8b;
}
.form_input__error {
  font-size: 1.2rem;
  color: #ff3366;
}

.modalContainer__form--sections {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.fileUpload__label_two {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.fileUpload__label_two span {
  font-family: 'Poppins', sans-serif;
  background-color: var(--light-grey);
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
}
