.header_links {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
}
.header_links_box {
  background-color: var(--primary-color);
  border-radius: 2rem;
  padding: 1rem 2rem;
  color: var(--white);
  display: flex;
  gap: 1rem;
  align-items: center;
}
.header_link {
  font-family: inherit;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: currentColor;
  text-decoration: none;
  font-size: 1.1rem;
  line-height: 1;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  transition: all 0.4s;
}
.header_link:hover {
  background-color: var(--white);
  color: var(--primary-color);
  /* transform: scale(1.02); */
}
