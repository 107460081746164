:root {
  --bg-color: #f2f8ff;
  --primary-color: #0f407a;
  --primary-color-light: #b6d7ff;
  --grey: #5e5e5e;
  --light-grey: #dddddd;
  --white: #ffffff;
  --black: #000000;
  --green: #4ed28b;
  --red: #ff0000;
  --orange: #ff7b01;
  --orange2: #fcac61;
  --light-orange: #fff1e0;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit;
}
html {
  font-size: 62.5%;
}
@media only screen and (max-width: 1200px) {
  html {
    font-size: 55%;
  }
}
@media only screen and (max-width: 768px) {
  html {
    font-size: 50%;
  }
}

body {
  box-sizing: border-box;
  background-color: var(--bg-color);
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  line-height: 1.7;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 10px;
  position: absolute;
  right: 10px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
