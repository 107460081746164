.container {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: flex-start;
}

.container_locked {
  display: flex;
  align-items: center;

  background-color: hsl(0, 0%, 100%);
  border-radius: 1rem;
  border: 1px solid rgb(209, 209, 209);
  width: 22rem;
}
.container_value_box {
  align-items: center;
  display: grid;
  flex: 1;
  flex-wrap: wrap;
  padding: 2px 8px;
}
.container_value {
  color: hsl(0, 0%, 20%);
  margin-left: 2px;
  margin-right: 2px;
  white-space: nowrap;
}
.lock_box {
  align-self: stretch;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.lock_separator {
  align-self: stretch;
  background-color: hsl(0, 0%, 80%);
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
}
.lock_icon_box {
  padding: 1.2rem;
  display: flex;
}
.lock_icon {
  height: 1.3rem;
  width: 1.3rem;
  line-height: 1;
  fill: hsl(0, 0%, 64%);
}
