.skeleton {
	border-radius: 1rem;
	position: relative;
	overflow: hidden;
}
.skeleton::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		90deg,
		rgba(240, 240, 241, 0.1),
		rgba(255, 255, 255, 0.9),
		rgba(231, 231, 234, 0.1)
	);
	animation: progress 1.5s ease-in-out infinite;
}
@keyframes progress {
	0% {
		transform: translate3d(-100%, 0, 0);
	}
	100% {
		transform: translate3d(100%, 0, 0);
	}
}

/* Table Skeleton */
.table__profile_image {
	height: 3rem;
	width: 3rem;
	background-color: rgba(228, 228, 228, 0.445);
}
.table__row_title {
	height: 1.5rem;
	/* width: 3rem; */
	background-color: rgba(228, 228, 228, 0.445);
}
.table__row_text {
	height: 1rem;
	/* width: 3rem; */
	background-color: rgba(228, 228, 228, 0.445);
}
.form_input__label {
	height: 1rem;
	width: 10rem;
	background-color: rgba(228, 228, 228, 0.445);
}
.form_input__container {
	height: 4rem;
	width: 25rem;
	background-color: rgba(228, 228, 228, 0.445);
}
.button {
	height: 4rem;
	width: 15rem;
	background-color: #0f3f7a15;
}
.buttonCancel {
	height: 1rem;
	width: 10rem;
	background-color: #0f3f7a15;
}
.form_input__text {
	height: 1rem;
	width: 20rem;
	background-color: rgba(228, 228, 228, 0.445);
}
.folder {
	height: 4rem;
	width: 19.5rem;
	background-color: rgba(228, 228, 228, 0.445);
}
.file {
	height: 9.5rem;
	width: 10rem;
	background-color: rgba(228, 228, 228, 0.445);
}
.fileUpload {
	height: 7rem;
	width: 100%;
	background-color: rgba(228, 228, 228, 0.445);
}

/* My Profile Skeleton */
.content_left_profile_image {
	height: 13rem;
	width: 13rem;
	border-radius: 50%;
	background-color: rgba(228, 228, 228, 0.445);
}
.content_left_profile_name {
	margin-top: 1rem;
	height: 1.8rem;
	width: 15rem;
	background-color: rgba(228, 228, 228, 0.445);
}
.content_left_profile_role {
	margin-top: 1.5rem;
	height: 1.3rem;
	width: 10rem;
	background-color: rgba(228, 228, 228, 0.445);
}
.content_left_contact_data_field {
	margin-top: 0.5rem;
	height: 1.5rem;
	width: 8rem;
	background-color: rgba(228, 228, 228, 0.445);
}

/* Ad Campaigns */
.content_card_image {
	height: 4rem;
	width: 4rem;
	border-radius: 0.5rem;
	background-color: rgba(228, 228, 228, 0.445);
}
.content_card_texts {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}
.content_card_heading {
	height: 1.6rem;
	max-width: 40vw;
	border-radius: 0.5rem;
	background-color: rgba(228, 228, 228, 0.445);
}
.content_card_sub_heading {
	height: 1.2rem;
	max-width: 30vw;
	border-radius: 0.5rem;
	background-color: rgba(228, 228, 228, 0.445);
}
.content_card_desc {
	height: 0.8rem;
	max-width: 38vw;
	border-radius: 0.5rem;
	background-color: rgba(228, 228, 228, 0.445);
}
.table__status {
	height: 2.5rem;
	width: 6rem;
	border-radius: 0.5rem;
	background-color: rgba(228, 228, 228, 0.445);
}
.switch {
	height: 2.5rem;
	width: 4rem;
	border-radius: 10rem;
	background-color: rgba(228, 228, 228, 0.445);
}
.ad_button {
	height: 2.5rem;
	width: 2.5rem;
	border-radius: 50%;
	background-color: rgba(228, 228, 228, 0.445);
}
.content_card_stat_number {
	margin-bottom: 0.5rem;
	height: 1rem;
	width: 2rem;
	border-radius: 0.5rem;
	background-color: rgba(228, 228, 228, 0.445);
}
.content_card_stat_bar {
	width: 3rem;
	border-radius: 0.5rem;
	background-color: rgba(228, 228, 228, 0.445);
}

/* Generated Ad Card */
.generated_ad_image {
	height: 150px;
	width: 100%;
	background-color: #0f3f7a15;
	border-radius: 1.5rem;
}

.generated_ad_title {
	height: 30px;
	width: 30%;
	background-color: #0f3f7a15;
}

.generated_ad_info {
	align-self: flex-end;
	height: 20px;
	width: 50%;
	background-color: #0f3f7a15;
}
