.timeDiv {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-top: 1rem;
  overflow-x: auto;
}

.timeButton,
.timeButton2 {
  padding: 0.4rem;
  border-radius: 5px;
  min-width: 6rem;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid var(--primary-color);
  background-color: transparent;
  font-family: inherit;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.4s;
}
.timeButton:disabled,
.timeButton2:disabled {
  cursor: not-allowed;
  border: 1px solid var(--bg-color);
}
.timeButton2:hover {
  background-color: var(--primary-color);
  color: var(--white);
}
