.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
.logo {
  height: 10rem;
}
.zipIcon {
  height: 5rem;
  width: 5rem;
  fill: var(--black);
}
.fileName {
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
}
.date {
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
}
.date span {
  font-family: 'Dosis', sans-serif;
}
