.content {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: repeat(4, 1fr);
	gap: 1rem;
}
.grad_color {
	background: linear-gradient(270deg, #01c2ff 0%, #0070ae 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

/* Chart 1 Start */
/* ============================================================== */
.chart1 {
	grid-column: 1/3;
	grid-row: 1/2;

	background-color: var(--white);
	border-radius: 1rem;
	padding: 2rem;

	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 3rem;
	align-items: center;
}
.chart1_bar {
	margin-top: 0.5rem;
	display: flex;
	flex-direction: column;
	/* gap: 0.2rem; */
}
.chart1_left {
	display: flex;
	flex-direction: column;
	/* gap: 0.5rem; */
	justify-content: center;
}
.chart1_bar_graph {
	position: relative;
	background-color: transparent;
	border: 1px solid var(--light-grey);
	border-radius: 1rem;
	/* overflow: hidden; */
}
.chart1_bar_graph_value {
	height: 1rem;
	border-radius: 1rem 0 0 1rem;
	background-image: linear-gradient(270deg, #01c2ff 0%, #0070ae 100%);
}
.chart1_bar_graph_arrow {
	position: absolute;
	top: 1.2rem;

	height: 0.8rem;
	width: 0.8rem;
	background-color: #5e5e5e;
	clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.chart1_bar_values {
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: space-between;

	font-size: 1.1rem;
	font-weight: 500;
}
.chart1_bar_values_num {
	font-family: 'Dosis', sans-serif;
	font-weight: 600;
	font-size: 1.3rem;
}
.chart1_right {
	display: flex;
	gap: 2rem;
	align-items: flex-start;
	flex-wrap: wrap;
}
.chart1_data {
	font-size: 1rem;
	font-weight: 500;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.3rem;
}
.chart1_data_value {
	font-family: 'Dosis', sans-serif;
	font-size: 3.5rem;
	font-weight: 300;
	line-height: 3.5rem;
}
.chart1_data_chart {
	display: flex;
	gap: 0.3rem;
	align-items: flex-end;
	height: 3rem;
}
.chart1_data_chart div {
	background-color: #0070ae;
	/* background-image: linear-gradient(180deg, #01c2ff 0%, #0070ae 100%); */
	width: 0.5rem;
	border-radius: 1rem;
}
.chart1_data button {
	margin-top: 1rem;
	font-family: inherit;
	color: var(--white);
	font-weight: 800;
	text-transform: uppercase;
	font-size: 1.1rem;
	background-color: #0070ae;
	background-image: linear-gradient(270deg, #01c2ff 0%, #0070ae 100%);
	border: 1px solid transparent;
	/* border-image: linear-gradient(270deg, #01c2ff 0%, #0070ae 100%) 1% / 2px; */
	padding: 1rem 2rem;
	border-radius: 1rem;
	transition: all 0.4s;
}
.chart1_data button:disabled {
	background-color: #535353;
	background-image: linear-gradient(270deg, #b1b1b1 0%, #535353 100%);
}
.chart1_data button:disabled:hover {
	cursor: not-allowed;
	background-color: transparent;
	background-image: none;
	border: 1px solid #535353;
	color: #535353;
}
.chart1_data button:hover {
	cursor: pointer;
	background-color: transparent;
	background-image: none;
	/* border-image: linear-gradient(270deg, #01c2ff 0%, #0070ae 100%) 1% / 2px; */
	border: 1px solid #0070ae;
	border-radius: 1rem;
	color: #0070ae;
}

/* Chart 1 Modal */
.modal_heading_inputs {
	display: flex;
	gap: 2rem;
	align-items: center;
}
.modal_heading_input_btns,
.modal_heading_input_selects {
	display: flex;
	/* gap: 1rem; */
	align-items: center;
}
.modal_heading_input_btns {
	background-color: var(--white);
	border-radius: 1.5rem;
	overflow: hidden;
	box-shadow: 0px 4px 15px 5px rgb(0 0 0 / 15%);
}
.modal_heading_input_btn {
	padding: 1rem 2rem;
	/* border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem; */
	font-family: inherit;
	background-color: transparent;
	/* border: 1px solid var(--white); */
	border: none;
	cursor: pointer;
	transition: all .4s;
}
.modal_heading_input_btn2 {
	font-family: inherit;
	background-color: var(--light-grey);
	border: 1px solid var(--light-grey);
	padding: .5rem 1rem;
	border-radius: 2rem;
	font-size: 1rem;
	cursor: pointer;
	transition: all .4s;
}
.modal_heading_input_btn2:hover {
	background-color: transparent;
}
.modal_heading_input_btn_active {
	background-color: var(--primary-color);
	color: var(--white);
}
.modal_total {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 1.3rem;
	font-weight: 600;
	text-align: center;
}
.modal_total span {
	font-size: 3rem;
	line-height: 3rem;
	font-family: 'Dosis', sans-serif;
}

/* ============================================================== */
/* Chart 1 End */

/* Chart 2 Start*/
/* ============================================================== */
.chart2 {
	grid-column: 3 / 4;
	grid-row: 1/2;
	background-color: var(--white);
	border-radius: 1rem;
	padding: 2rem 2rem;
}

/* ============================================================== */
/* Chart 2 End */

/* Chart 3 Start*/
/* ============================================================== */
.chart3 {
	grid-column: 4 / -1;
	grid-row: 1/2;
	background-color: var(--white);
	border-radius: 1rem;
	padding: 2rem;

	display: flex;
	flex-direction: column;
	gap: 1rem;
	/* justify-content: center; */
}
.chart3_chart {
	display: flex;
	gap: 1rem;
}
.chart3_chart_text {
	font-size: 1rem;
	font-weight: 600;
	text-transform: uppercase;
	width: 20rem;
	color: var(--grey);
}
.chart3_chart_bar {
	position: relative;
	background-color: var(--light-grey);
	width: calc(100% - 20rem);
	border-radius: 0 1rem 1rem 0;
}
.chart3_chart_bar div {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	background-color: #0070ae;
	background-image: linear-gradient(270deg, #01c2ff 0%, #0070ae 100%);
	border-radius: 0 1rem 1rem 0;
}
.chart3_chart_bar p {
	position: absolute;
	top: 0;
	right: 1rem;
}
/* ============================================================== */
/* Chart 3 End */

/* Chart 4 Start*/
/* ============================================================== */
.chart4 {
	grid-column: 1 / 2;
	grid-row: 2/3;
	background-color: var(--white);
	border-radius: 1rem;
	padding: 2rem;
}
.chart4_heading {
	font-size: 1.5rem;
	font-weight: 600;
}
.chart4_heading span {
	font-family: 'Dosis', sans-serif;
}
.chart4_data {
	font-family: 'Dosis', sans-serif;
	display: flex;
	align-items: flex-end;
	gap: 0.5rem;
}
.chart4_data_number {
	font-size: 3.5rem;
	line-height: 3.5rem;
}
.chart4_active {
	font-size: 1.5rem;
	line-height: 1.5rem;
	text-transform: uppercase;
}
.chart4_chart {
	/* margin-top: 1rem; */
	display: flex;
	align-items: flex-end;
	gap: 1rem;
}
.chart4_chart_bar {
	display: flex;
	gap: 0.5rem;
	flex-direction: column;
	justify-content: flex-end;
	height: 8rem;
}
.chart4_chart_bar div {
	width: 2rem;
	background-color: #0070ae;
	background-image: linear-gradient(180deg, #01c2ff 0%, #0070ae 100%);
	border-radius: 1rem;
	transition: all 0.4s;
}
/* ============================================================== */
/* Chart 4 End */

/* Chart 5 Start*/
/* ============================================================== */
.chart5 {
	grid-column: 1 / 2;
	grid-row: 3/4;
	background-color: var(--white);
	border-radius: 1rem;
	padding: 2rem;
}
.chart5_stats {
	/* margin-top: 1rem; */
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: center;
}

.chart5_stat {
	background-color: transparent;
	border: 3px solid #0070ae;
	height: 7rem;
	width: 7rem;
	border-radius: 50%;

	display: flex;
	align-items: center;
	justify-content: center;
}

.chart5_stat_data {
	font-size: 2.2rem;
	font-family: 'Dosis', sans-serif;
	line-height: 2.5rem;
	text-align: center;
}

.chart5_stat_data span {
	display: block;
	font-family: 'Raleway', sans-serif;
	font-size: 0.9rem;
	line-height: 0.9rem;
}
/* ============================================================== */
/* Chart 5 End */

/* Chart 6 Start*/
/* ============================================================== */
.chart6 {
	grid-column: 2 / -1;
	grid-row: 2/4;
	background-color: var(--white);
	border-radius: 1rem;
	padding: 2rem;

	display: flex;
	gap: 4rem;
	align-items: center;
}
.chart6_left {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}
.chart6_left_top {
	display: flex;
	gap: 2rem;
	align-items: flex-start;
	justify-content: center;
}
.chart6_left_top_data {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
}
.chart6_left_top_data_number {
	font-size: 3.5rem;
	line-height: 3.5rem;
	font-family: 'Dosis', sans-serif;
}
.chart6_left_top_data_bar {
	background-color: var(--light-grey);
	border-radius: 1rem;
	overflow: hidden;

	height: 0.5rem;
	width: 100%;
}
.chart6_left_top_data_bar div {
	height: 100%;
	background-color: #0070ae;
	background-image: linear-gradient(270deg, #01c2ff 0%, #0070ae 100%);
	border-radius: 1rem;
}
.chart6_left_top_data_bar_red div {
	height: 100%;
	background-color: #0070ae;
	background-image: linear-gradient(270deg, #ff9101 0%, #ae4000 100%);
	border-radius: 1rem;
}
.chart6_left_top_data_bar_red2 div {
	height: 100%;
	background-color: #0070ae;
	background-image: linear-gradient(270deg, #ff3c01 0%, #ae1a00 100%);
	border-radius: 1rem;
}
.chart6_left_top_data_text {
	width: 11rem;
	text-align: center;
	font-weight: 600;
	line-height: 1.3rem;
}
.chart6_area_chart {
	width: 35vw;
	height: 20rem;
}
.chart6_right {
	padding-left: 1rem;
	padding-bottom: .5rem;
	display: flex;
	gap: 1rem;
	flex-direction: column;
	align-items: center;
	/* justify-content: center; */
	position: relative;
	overflow: hidden;
}
.chart6_right::before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(255, 255, 255, 0.2);
	backdrop-filter: blur(4px);
	z-index: 100;
}
.chart6_right_title {
	font-size: 1.3rem;
}
.chart6_right_charts {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}
.chart6_right_chart {
	display: flex;
	align-items: center;
	gap: 2rem;
}
.chart6_right_chart_circle {
	position: relative;
	background-color: #0070ae;
	border-radius: 50%;
	height: 8rem;
	width: 8rem;
	padding: 0.5rem;

	display: flex;
	align-items: center;
	justify-content: center;
}
.chart6_right_chart_circle p {
	position: absolute;
	font-family: 'Dosis', sans-serif;
	font-size: 2rem;
	font-weight: 600;
	color: var(--white);
}
.chart6_right_chart_circle p span {
	font-size: 1rem;
}
.chart6_right_bar {
	display: flex;
	align-items: flex-end;
	gap: 1rem;
	border-radius: 5rem;
	border: 1px solid var(--light-grey);
	padding: 1rem 4rem;
}
.chart6_right_bar_chart {
	display: flex;
	gap: 0.3rem;
	align-items: flex-end;
	height: 5.5rem;
}
.chart6_right_bar_chart div {
	background-color: #0070ae;
	/* background-image: linear-gradient(180deg, #01c2ff 0%, #0070ae 100%); */
	width: 0.5rem;
	border-radius: 1rem;
}
.chart6_right_bar_text {
	font-size: 1.1rem;
	font-weight: 500;
	color: var(--grey);
	margin-left: 1rem;
	padding-left: 1rem;
	border-left: 1px solid var(--light-grey);
	width: 10vw;
}
/* ============================================================== */
/* Chart 6 End */

/* Chart 7 Start*/
/* ============================================================== */
.chart7 {
	grid-column: 1 / 3;
	grid-row: 4/-1;
	background-color: var(--white);
	border-radius: 1rem;
	padding: 2rem;
	/* position: relative;
	overflow: hidden; */
}
/* .chart7::before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(255, 255, 255, 0.2);
	backdrop-filter: blur(4px);
	z-index: 100;
} */
.chart7_heading {
	font-size: 1.5rem;
	font-weight: 600;
}
.chart7_data {
	display: flex;
	gap: 2rem;
	align-items: center;
}
.chart7_charts {
	margin-top: 1rem;
	display: flex;
	/* gap: 0.5rem; */
	flex-direction: column;
	position: relative;
}
.chart7_indication {
	position: absolute;
	height: 100%;
	width: 1px;
	left: calc(50% - 6.5rem);
	/* background-color: rgba(255, 0, 0, 0.5); */
	background-color: rgba(255, 0, 0, 0);
}
.chart7_chart {
	display: flex;
	align-items: center;
	gap: 1rem;
}
.chart7_chart_title {
	font-size: 1.2rem;
	font-weight: 600;
	color: var(--grey);
	width: 8rem;
}
.chart7_bubbles {
	display: flex;
	gap: 0.2rem;
}
.chart7_bubbleBox {
	position: relative;
}
.chart7_bubbleBox:hover.chart7_bubbleBox p {
	display: block;
}
.chart7_bubbleBox p {
	/* width: 20rem; */
	white-space: nowrap;
	display: none;
	position: absolute;
	top: -2.5rem;
	background-color: #ff9101;
	color: var(--white);
	padding: .5rem 1rem;
	border-radius: 1rem;
	z-index: 1000;
}
.chart7_bubble {
	height: 1.4rem;
	width: 1.4rem;
	background-color: var(--light-grey);
	border-radius: 50%;
}
.chart7_bubble_full {
	height: 1.4rem;
	width: 1.4rem;
	background-color: #0070ae;
	border-radius: 50%;
}
.chart7_bubble_full_below {
	height: 1.4rem;
	width: 1.4rem;
	/* background-color: var(--red); */
	background-color: #0070ae;
	border-radius: 50%;
}
.chart7_chart_value {
	font-size: 1.5rem;
	font-weight: 600;
	font-family: 'Dosis', sans-serif;
}
.chart7_stats {
	display: flex;
	gap: 1rem;
}
.chart7_stat {
	display: flex;
	flex-direction: column;
	align-items: center;
	/* background-color: var(--light-grey); */
	background-image: linear-gradient(0deg, rgba(223, 223, 223, 0), rgba(0, 113, 174, 0.1));
	padding: 1rem 0.8rem;
	border-radius: 2rem;
}
.chart7_stat_value {
	font-size: 3rem;
	line-height: 4rem;
	font-weight: 600;
	font-family: 'Dosis', sans-serif;
	color: var(--grey);
}
.chart7_stat_dept {
	align-self: stretch;
	background-color: #0070ae;
	border-radius: 2rem;
	padding: 0.5rem 1rem;
	font-size: 1.2rem;
	font-weight: 600;
	color: var(--white);
	text-align: center;
}
.chart7_stat_text {
	font-size: 1.3rem;
	color: var(--grey);
}
/* ============================================================== */
/* Chart 7 End */

/* Chart 8 Start*/
/* ============================================================== */
.chart8 {
	grid-column: 3 / 4;
	grid-row: 4/-1;
	background-color: var(--white);
	border-radius: 1rem;
	padding: 2rem;
	display: flex;
	gap: 2rem;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
}
.chart8::before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(255, 255, 255, 0.2);
	backdrop-filter: blur(4px);
	z-index: 100;
}
.chart8_chart {
	display: flex;
	gap: 0.5rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	font-size: 1.3rem;
	font-weight: 600;
}
.chart8_circle {
	position: relative;
	background-color: #0070ae;
	border-radius: 50%;
	height: 15rem;
	width: 15rem;
	padding: 0.8rem;

	display: flex;
	gap: 2rem;
	align-items: center;
	justify-content: center;
}
.chart8_circle_chart {
	height: 15rem;
	width: 15rem;
	padding: 0.8rem;

	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 10;
}
.chart8_circle_info {
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 1;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 0.5rem;
	color: var(--white);
}
.chart8_circle_info_num {
	text-align: center;
	font-size: 2.5rem;
	line-height: 2.5rem;
	font-family: 'Dosis', sans-serif;
}
.chart8_circle_info_num span {
	font-size: 1rem;
	line-height: 1.4rem;
}
.chart8_circle_info_text {
	font-size: 1.1rem;
	line-height: 1.2rem;
	text-align: center;
}
/* ============================================================== */
/* Chart 8 End */

/* Chart 9 Start*/
/* ============================================================== */
.chart9 {
	grid-column: 4 / 5;
	grid-row: 4/-1;
	background-color: var(--white);
	border-radius: 1rem;
	padding: 2rem;
	display: flex;
	gap: 2rem;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
}
.chart9::before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(255, 255, 255, 0.2);
	backdrop-filter: blur(4px);
	z-index: 100;
}
/* ============================================================== */
/* Chart 9 End */

/* Chart 10 Start*/
/* ============================================================== */
.chart10 {
	grid-column: 5 / -1;
	grid-row: 4/-1;
	background-color: var(--white);
	border-radius: 1rem;
	padding: 2rem;
	display: flex;
	gap: 2rem;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
}
.chart10::before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(255, 255, 255, 0.2);
	backdrop-filter: blur(4px);
	z-index: 100;
}
/* ============================================================== */
/* Chart 10 End */
