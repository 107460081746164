.container {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: min-content repeat(3, min-content);
}
.folderContainer {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  background-color: var(--white);
  border-radius: 3rem;
  padding: 2rem 3rem;
}
.folderHeading {
  font-size: 1.7rem;
  font-weight: 600;
}
.folders {
  margin: 1rem 0;
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}
.folder,
.addFolder,
.folderSelected {
  display: flex;
  align-items: center;
  font-family: inherit;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 19.5rem;
  cursor: pointer;
  transition: all 0.4s;
  position: relative;
}
.folder {
  gap: 1rem;
  justify-content: space-between;
  border: 1px solid var(--light-grey);
  background-color: var(--light-grey);
}
.folderSelected {
  gap: 1rem;
  justify-content: space-between;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
}
.folder:hover .folderNameDetails,
.folderSelected:hover .folderNameDetails {
  /* display: block; */
  opacity: 1;
  visibility: visible;
}
.folderNameDetails {
  /* display: none; */
  transition: all 0.6s;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -1.5rem;
  left: 2.5rem;
  white-space: nowrap;
  background-color: var(--grey);
  font-size: 1rem;
  color: var(--white);
  padding: 0.5rem 1rem;
  border-radius: 0.8rem;
  z-index: 100;
}
.folderLeft {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.folderIcon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--orange);
}
.folderSelected .folderIcon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--white);
}
.folderName {
  font-size: 1.2rem;
  font-weight: 600;
}
.folderSelected .folderName {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--white);
}
.folderfiles {
  font-size: 1rem;
  font-weight: 600;
}
.folderSelected .folderfiles {
  font-size: 1rem;
  font-weight: 600;
  color: var(--white);
}
.folder:hover,
.file:hover,
.recentUpload:hover {
  border: 1px solid var(--grey);
}
.addFolder {
  gap: 0.5rem;
  justify-content: center;
  border: 1px solid var(--primary-color-light);
  background-color: var(--primary-color-light);
}
.addFolderIcon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--primary-color);
}
.addFolderName {
  font-size: 1.2rem;
  font-weight: 800;
  color: var(--primary-color);
}
.addFolder:hover {
  transform: translateX(0.5rem) scale(1.02);
}

.folderFiles {
  grid-column: 1 / 3;
  grid-row: 2/-1;
  background-color: var(--white);
  border-radius: 3rem;
  padding: 2rem 3rem;
}
.folderFilesTop {
  display: flex;
  justify-content: space-between;
}
.folderFilesTopFlex {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.uploadBtn {
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--primary-color);
  background-color: transparent;
  border: none;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  transition: all 0.4s;

  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.uploadBtnIcon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--primary-color);
}
.uploadBtn:hover {
  border-bottom: 1px solid var(--primary-color);
}
.folderFilesSub {
  font-size: 1.3rem;
  font-weight: 600;
}
.file {
  background-color: var(--light-grey);
  border: 1px solid var(--light-grey);
  font-family: inherit;
  font-size: 1.2rem;
  padding: 1.5rem;
  border-radius: 1rem;
  width: 10rem;
  height: 9.5rem;
  cursor: pointer;
  transition: all 0.4s;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  position: relative;
}
.fileIcon {
  height: 3rem;
  width: 3rem;
  fill: var(--orange);
}
.fileNmme {
  font-size: 1.1rem;
  font-weight: 600;
}
.file_lock {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--grey);

  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.recentUploadsContainerAdmin {
  grid-column: 1/-1;
  grid-row: 1/-1;
  background-color: var(--white);
  border-radius: 3rem;
  padding: 2rem 3rem;
}
.recentUploadsContainer {
  grid-column: 3/-1;
  grid-row: 1/-1;
  background-color: var(--white);
  border-radius: 3rem;
  padding: 2rem 3rem;
}
.recentUploads {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.recentUpload {
  display: flex;
  gap: 1rem;
  align-items: center;

  font-family: inherit;
  background-color: var(--light-grey);
  border: 1px solid var(--light-grey);
  border-radius: 1rem;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.4s;
}
.recentUploadIcon {
  height: 3.5rem;
  width: 3.5rem;
  fill: var(--orange);
}
.recentUploadData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.recentUploadName {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.recentUploadDate {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.4;
  color: var(--grey);
}

.storageContainer {
  grid-column: 3/-1;
  grid-row: 3/-1;
  background-color: var(--white);
  border-radius: 3rem;
  padding: 2rem 3rem;
}
.storageContainerLines {
  margin-top: 1rem;
  position: relative;
  height: 1rem;
}
.storageContainerLineFull {
  position: absolute;
  height: 0.8rem;
  width: 100%;
  background-color: var(--light-grey);
}
.storageContainerLineUsed {
  position: absolute;
  height: 0.8rem;
  width: 80%;
  background-color: var(--red);
}
.storageContainerTitle {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--grey);
}
.storageContainerButton {
  margin-top: 0.5rem;
  background-color: transparent;
  border: brown;
  border-bottom: 1px solid transparent;
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primary-color);
  cursor: pointer;
  transition: all 0.4s;
}
.storageContainerButton:hover {
  border-bottom: 1px solid var(--primary-color);
}

.addFolderInput {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 19.5rem;

  padding: 0.55rem 1rem;
  border: 1px solid var(--light-grey);
  border-radius: 0.5rem;
}
.addFolderInputIcon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--primary-color);
}
.addFolderInputBox {
  font-family: inherit;
  font-size: 1.2rem;
  color: var(--primary-color);
  border: none;
  background-color: transparent;
  outline: none;
  width: 9.5rem;
}
.addFolderInputSubmitBtn,
.addFolderInputCloseBtn {
  border: none;
  cursor: pointer;
  padding: 0.6rem;
  border-radius: 0.5rem;

  display: flex;
  align-items: center;
}
.addFolderInputSubmitBtn {
  background-color: var(--primary-color);
}
.addFolderInputCloseBtn {
  background-color: var(--red);
}
.addFolderInputSubmitBtnIcon,
.addFolderInputCloseBtnIcon {
  height: 1.2rem;
  width: 1.2rem;
  fill: var(--white);
}
