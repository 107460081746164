.detail_container {
  display: flex;
  gap: 2rem;
  margin: 0 2rem;
  margin-bottom: 2rem;
}
.detail_container_right {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.detail_container_left,
.detail_container_right_top,
.detail_container_right_bottom {
  background-color: var(--white);
  border-radius: 2rem;
  padding: 1.5rem 2rem;
  width: 40rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.detail_container_right_bottom {
  flex-direction: row;
  gap: 1.5rem;
}
.detail_container_title {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}
.detail_title {
  color: var(--grey);
  font-size: 1.7rem;
}
.detail_text {
  color: var(--grey);
  font-size: 1.2rem;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}
.detail_text span {
  font-style: italic;
  font-size: 1.4rem;
}
.detail_chip {
  margin-top: 1rem;
  background-color: var(--primary-color);
  border-radius: 1.5rem;
  color: var(--white);
  padding: 0.5rem 1.5rem;
  font-size: 1.3rem;
}
.detail_address {
  font-size: 1.3rem;
  color: var(--grey);
  font-weight: 600;
}
.margin_top {
  margin-top: 2rem;
}
