.button,
.button_disabled {
	font-family: inherit;
	font-size: 1.2rem;
	font-weight: 600;
	color: var(--white);
	padding: 1rem 2rem;
	border-radius: 1rem;
	transition: all .4s;

	display: flex;
	gap: .5rem;
	align-items: center;
}
.button {
	background-color: var(--primary-color);
	border: 1px solid var(--primary-color);
	cursor: pointer;
}
.button_disabled {
	background-color: var(--grey);
	border: 1px solid var(--grey);
}
.button span,
.button_disabled span {
	height: 2px;
	width: 2.5rem;
	background-color: var(--white);
	transition: all .4s;
}
.button:hover {
	background-color: transparent;
	color: var(--primary-color);
}
.button:hover span {
	background-color: var(--primary-color);
}
