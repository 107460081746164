.paginationContainer {
  max-width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}
.paginationButtons {
  font-family: 'Poppins', sans-serif;
  list-style: none;
  text-decoration: none !important;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
}
.paginationButtons li {
  width: 3rem;
  height: 3rem;
}
.paginationButtons a {
  color: var(--primary-color);
  background-color: transparent;
  border: 1px solid var(--primary-color);
  /* padding: 0.5rem 1rem; */
  border-radius: 0.5rem;
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: block;
  text-align: center;
  transition: transform 0.4s;
  font-size: 1.1rem;
}
.paginationButtons a,
.paginationPrevious a,
.paginationNext a {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;
}
.paginationPrevious a,
.paginationNext a {
  font-size: 1.3rem;
}
.paginationButtons a:hover {
  transition: transform 0.4s;
  color: var(--white);
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  transition: all 0.4s;
}
.icon:hover {
  cursor: pointer;
  transition: transform 0.3s;
  transform: scale(105%);
}
.paginationActiveLink a {
  color: var(--white);
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.paginationDisabled a {
  color: var(--black);
  background-color: var(--light-grey);
  border: 1px solid var(--light-grey);
}
.paginationDisabled a:hover {
  cursor: not-allowed;
  color: var(--black);
  background-color: var(--light-grey);
  border: 1px solid var(--light-grey);
}
