.advertise_container {
  display: flex;
  column-gap: 2rem;
  padding: 1rem;
  /* height: 30vh; */
}

.advertise_left_container {
  flex: 2;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: white;
  border-radius: 2rem;
  row-gap: 1rem;
}

.advertise_right_container {
  /* flex: 1;
	padding: 1rem;
	border-radius: 2rem;

	align-items: center;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center; */

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
}

.advertise_right_container_transperent {
  flex: 1;
  padding: 1rem;
  border-radius: 2rem;
  background-color: white;
  height: 30vh;
}
.imageBox {
  border-radius: 1rem;
  background-color: transparent;
  border: 1px solid var(--light-grey);
  padding: 1rem;

  display: flex;
  gap: 1rem;
  align-items: flex-start;
  justify-content: center;
}

.adImage_container h4 {
  font-size: 1.5rem;
}
.adImage_container p,
.advertise_right_container_error {
  font-size: 1.2rem;
  color: var(--red);
  min-height: 2rem;
}
.inputBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inputBox input {
  width: 100%;
  cursor: pointer;
  /* height: 1rem; */
}
.inputBox p {
  font-size: 1.3rem;
  color: var(--grey);
  white-space: nowrap;
}

.textInput__input {
  padding: 1rem 0;
  width: 35px;
  border: none;

  outline: none;
  font-family: inherit;
  font-size: 1.2rem;
  color: #000;
}
.advertise_row {
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr 0.1fr;
  column-gap: 1rem;
}

.advertise_rule_row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.advertise_rule_input {
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.advertise_rule_input label {
  font-size: 1.2rem;
  font-weight: 600;
}
.advertise_rule_input label span {
  font-size: 1rem;
  background-color: var(--light-grey);
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
}
.advertise_select {
  width: 100%;
  height: 3.5rem;
  border-radius: 1rem;
  padding: 0.5rem;
  border: 1px solid rgb(209, 209, 209);
  transition: all 0.5s ease-out;
}

.add_button {
  padding: 0.5rem;
  color: rgb(77, 77, 148);
}

.add_button:hover {
  padding: 0.5rem;
  color: rgb(47, 47, 144);
  cursor: pointer;
}

.add_rule_list {
  display: flex;
  flex-direction: column;
  flex: 2;
  row-gap: 1rem;
  margin-bottom: 2rem;
  /* max-height: 15rem; */
}

.fixed_height {
  /* height: 45vh !important; */
  overflow: auto;
}

.advertise_footer {
  height: 5vh !important;
  float: right;
  display: flex;
  column-gap: 1rem;
}

.advertise_error {
  color: white;
  background-color: red;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 1rem;
  font-size: 1.2rem;
}

.advertise_success {
  color: white;
  background-color: green;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 1rem;
  font-size: 1.2rem;
}

.advertise_select select {
  display: none;
}

.advertise_select:hover {
  border: 1px solid rgb(0, 0, 0);
}

.advertise_upload_icon {
  height: 3rem;
  fill: var(--grey);
  transition: all 0.4s;
  margin-top: 1rem;
}

.advertise_right_container > input {
  display: none;
}

.advertise_right_container_upload > input {
  display: none;
}

.advertise_upload_title {
  font-size: 1.2rem;
}

.advertise_upload_icon:hover {
  color: var(--primary-color);
  cursor: pointer;
}
.advertise_delete_box {
  border: 1px solid var(--red);
  background-color: var(--red);
  padding: 0.7rem;
  border-radius: 1rem;
  align-self: center;
  transition: 0.4s all;
  cursor: pointer;
}
.advertise_delete_box:hover {
  border: 1px solid var(--red);
  background-color: transparent;
}
.advertise_delete_icon {
  fill: var(--white);
  height: 2rem;
  width: 2rem;
  transition: 0.4s all;
}

.advertise_delete_icon_white {
  fill: transparent;
  height: 4rem;
  width: 4rem;
  align-self: center;
  transition: 0.4s all;
  cursor: pointer;
}

.rule_row_foot {
  display: flex;
  column-gap: 1rem;
}

.advertise_delete_box:hover .advertise_delete_icon {
  fill: var(--red);
}

.remove_button {
  color: red;
  border: 1px solid red;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
  transition: 0.4s all;
}

.remove_button:hover {
  cursor: pointer;
  border: 1px solid red;
  background-color: red;
  color: white;
}

.advertise_right_container_grid {
  flex: 1;
  width: 100%;
  display: grid;
  overflow: auto;
  position: relative;
  grid-gap: 5px;
  grid-template-columns: auto auto auto;
}

.advertise_right_container_grid_item {
  height: 6rem;
  position: relative;
  background-size: cover;
}

.advertise_right_container_grid_item > img {
  width: 100%;
}

.advertise_right_container_grid_item > svg {
  fill: var(--primary-color);
  height: 2rem;
  position: absolute;
  top: 0;
  right: 5;
}

.advertise_right_container_table {
  width: 100%;
}

.advertise_right_container_table > thead > tr > th {
  text-align: center;
  font-size: 1.4rem;
  border: none;
  z-index: none;
}

.advertise_right_container_table_header_tr {
  text-align: center;
  font-size: 1.4rem;
  border-color: aliceblue;
  background-color: aliceblue;
}

.advertise_right_container_table_body_tr {
  text-align: center;
  font-size: 1.4rem;
  display: inline-grid;
  position: relative;
}

.advertise_right_container_table > tbody > tr > td {
  text-align: center;
  font-size: 1.4rem;
  z-index: 100;
}

.advertise_right_container_table > tbody > tr > td > img {
  height: 1rem;
  transition: 0.4s all;
}

.advertise_right_container_table > tbody > tr > td > img:hover {
  transform: scale(20);
  cursor: pointer;
}

.advertise_img_delete_icon {
  z-index: 9;
  position: absolute;
  /* margin-left: -2rem; */
  margin-left: 1rem;
  margin-top: 0.5rem;
  background-color: rgba(255, 0, 0, 0.7);
  border-radius: 3rem;
  height: 3vh;
  width: 3vh;
  padding: 0.5rem;
  fill: white;
  cursor: pointer;
}

.ad_setting_container {
  display: grid;
  gap: 2rem;
  /* grid-template-columns: repeat(2, 1fr); */
  /* grid-template-columns: calc(40% - 2rem) 60%; */
  grid-template-columns: calc(50% - 2rem) 50%;
}
.ad_setting_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: #ffffff;
  padding: 1.5rem 2rem;
  /* padding-top: 2.5rem; */
  border-radius: 2rem;
  margin-bottom: 2rem;
}
.ad_setting_image {
  width: 30rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.14);
}
.ad_setting_toggleBoxes {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}
.ad_setting_toggleBox {
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--light-grey);
  padding: 0 1rem;
  padding-bottom: 1rem;
}
.ad_setting_toggleBox p {
  font-size: 1.1rem;
  font-weight: 600;
}

.ad_setting_input {
  display: flex;
  gap: 1rem;
  align-items: center;
  position: relative;
}
.ad_setting_input_disabled {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}
.ad_setting_input label {
  font-size: 1.2rem;
  font-weight: 600;
  min-width: 10rem;
}
.ad_setting_input div {
  background-color: var(--light-grey);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}
.ad_setting_inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.ad_setting_input input {
  font-family: 'Dosis', sans-serif;
  font-weight: 600;
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
}

@media (max-width: 769px) {
  .ad_setting_container {
    display: flex;
    flex-wrap: wrap;
  }

  .ad_setting_box {
    width: 100%;
  }

  .advertise_right_container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
}

@media (max-width: 426px) {
  .advertise_row {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
}
