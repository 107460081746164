.items {
	max-width: 52rem;
	min-width: 25rem;
	min-height: 4rem;
	overflow: auto;
	display: flex;
	gap: 1rem;
	align-items: center;
	flex-wrap: wrap;
}

.item {
	background-color: transparent;
	border: 1px solid var(--primary-color);
	border-radius: .5rem;
	padding: .3rem 1rem;
	font-family: inherit;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.1;
	color: var(--primary-color);
	cursor: pointer;
	transition: all .4s;
}

.item:hover {
	transform: translateX(.2rem) scale(1.05);
}

.itemExists {
	background-color: var(--primary-color);
	border: 1px solid var(--primary-color);
	border-radius: .5rem;
	padding: .3rem 1rem;
	font-family: inherit;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.1;
	color: var(--white);
	cursor: pointer;
	transition: all .4s;
}

.itemExists:hover {
	transform: translateX(.2rem) scale(1.05);
}