.container {
  display: flex;
  margin-top: 4rem;
  column-gap: 2rem;
  row-gap: 2rem;
  flex-wrap: wrap;
  flex-direction: row;
}
.card {
  width: 25rem;
  background-color: #fff;
  border-radius: 1.5rem;
  min-height: 24rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
}
.card2 {
  width: 25rem;
  background-color: rgba(228, 228, 228, 0.445);
  border-radius: 1.5rem;
  min-height: 24rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
}
.questionRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.buttonsRow {
  display: flex;
}
.questionIndex {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #000000;
  text-transform: capitalize;
}
.title {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #000000;
  text-transform: capitalize;
}
.hint {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #5e5e5e;
  text-transform: capitalize;
}
.line {
  margin: 1rem 0;
  background-color: #d9d9d9;
  border: 1px solid #d0d0d0;
}
.option {
  margin-top: 0.5rem;
}

.circle {
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.8rem;
  background: #000000;
  margin-right: 0.5rem;
}
.optionText {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #000000;
}

.optionValue {
  width: 90%;
  word-wrap: break-word;
}
.optionEnter {
  display: flex;
  align-items: center;
}
.underline {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #000000;
  margin: 0 0.5rem;
}
.pencilIcon {
  width: 2rem;
  height: 2rem;
  fill: #5e5e5e;
}

/* modal css */

.modalcontainer {
  width: 90rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.left {
  padding: 2rem;
}
.right {
  padding: 2rem;
}
.input {
  background-color: #ffffff;
  padding: 1.5rem 2rem;
  padding-bottom: 2.5rem;
  border-radius: 2rem;
  margin-bottom: 2rem;
}
.input2 {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 1.5rem 2rem;
  padding-bottom: 2.5rem;
  border-radius: 2rem;
  /* margin-bottom: 2rem; */
}
.switchContainer {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.switchText {
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #0f407a;
}
.button {
  margin-left: 1rem;
}
.allOptions {
  margin-top: 1rem;
  max-height: 30rem;
  overflow: scroll;
}
.optionCard {
  display: flex;
  width: 100%;
  margin-top: 1rem;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 1.5rem 2rem;
  /* padding-bottom: 2.5rem; */
  border-radius: 2rem;
}
.optionCardTitle {
  width: 70%;
  background: rgba(1, 95, 209, 0.06);
  border-radius: 1rem;
  padding: 1rem;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #000000;
}
.buttons {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

/* error */

.form_input__error {
  font-size: 1.2rem;
  color: #ff3366;
}

.DeleteModel {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #000000;
  margin-bottom: 2rem;
}

/* answerSkeletons  */

.container2 {
  /* padding: 3rem 0; */
}
.card3 {
  width: 100rem;
  padding: 1rem 2rem;
  background-color: rgba(228, 228, 228, 0.445);
  border-radius: 1.5rem;
  margin-bottom: 1rem;
  min-height: 6.5rem;
}
