.container {
  display: grid;
  grid-template-columns: 16rem repeat(1, 1fr);
  height: 100vh;
  width: 100%;
  transition: all 0.5s ease;
}
.flex {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.flexCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}
.displayflex {
  display: flex;
}
.displayflexCol {
  flex-direction: column;
}
.alignCenter {
  align-items: center;
}
.alignStart {
  align-items: flex-start;
}
.alignEnd {
  align-items: flex-end;
}
.alignSelfStart {
  align-self: flex-start;
}
.alignSelfCenter {
  align-self: center;
}
.alignSelfEnd {
  align-self: flex-end;
}
.justifyCenter {
  justify-content: center;
}
.justifyStart {
  justify-content: flex-start;
}
.justifyEnd {
  justify-content: flex-end;
}
.justrifySpaceBetween {
  justify-content: space-between;
}
.justrifySpaceAround {
  justify-content: space-around;
}
.justrifySpaceEvenly {
  justify-content: space-evenly;
}
.gap-1 {
  gap: 1rem;
}
.gap-2 {
  gap: 2rem;
}
.gap-3 {
  gap: 3rem;
}
.gap-4 {
  gap: 4rem;
}
.gap-5 {
  gap: 5rem;
}
.gap-6 {
  gap: 6rem;
}
.gap-7 {
  gap: 7rem;
}
.gaphalf {
  gap: 0.5rem;
}
.flexWrap {
  flex-wrap: wrap;
}
.dangerText {
  color: var(--red);
  font-size: 1.1rem;
  line-height: 1.2;
  font-weight: 600;
}
.primaryText {
  color: var(--primary-color);
  font-size: 1.1rem;
  line-height: 1.2;
  font-weight: 600;
}
.noContent_table_row {
  border-bottom: none !important;
}
.noContent_table_row:hover {
  background-color: transparent !important;
}
.table__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}
.table__footer_text {
  font-size: 1.2rem;
  font-weight: 600;
}
.react-calendar {
  border: 0 !important;
}

/* ============================================Toggle CSS Start============================================ */

.switch {
  position: relative;
  display: inline-block;
  min-width: 3.5rem;
  height: 2.1rem;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #8b8b8b;
  box-shadow: 0.5rem 0.2rem 1rem 2px rgb(0 0 0 / 14%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 1.4rem;
  width: 1.4rem;
  left: 4px;
  bottom: 4px;
  background-color: var(--light-grey);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.checkbox {
  opacity: 0;
}

.checkbox:checked + .slider {
  background-color: var(--primary-color);
}

.checkbox:focus + .slider {
  box-shadow: 0 0 1px var(--primary-color);
}

.checkbox:checked + .slider:before {
  background-color: #e7e7ea;
  -webkit-transform: translateX(1.3rem);
  -ms-transform: translateX(1.3rem);
  transform: translateX(1.3rem);
}

.slider.round {
  border-radius: 3.4rem;
}

.slider.round:before {
  border-radius: 50%;
}

/* ============================================Toggle CSS End============================================ */

/* ============================================Pagination CSS Start============================================ */

.paginationActive {
  background-color: var(--primary-color);
}

.paginationActive a {
  color: #e7e7ea !important;
}

.paginationBttns {
  list-style: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.paginationBttns li {
  height: 3rem;
  width: 3rem;
  border: 1px solid var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  transition: all 0.4s;
  overflow: hidden;
}

.paginationBttns a {
  padding: 1rem;
  color: var(--primary-color);
  line-height: 1;
  cursor: pointer;
  transition: all 0.4s;
}

.paginationBttns li:hover {
  color: #e7e7ea;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
}

.paginationBttns a:hover {
  color: #e7e7ea;
}

.Toastify__toast-container {
  z-index: 100000;
  font-size: 1.5rem;
}

/* ============================================Pagination CSS End============================================ */

/* ============================================Context Menu CSS Start============================================ */
nav.react-contextmenu {
  position: fixed;
  width: 20rem;
  left: 0;
  top: calc(100% + 10px);
  border-radius: 1rem;
  background-color: var(--white);
  padding: 1rem 0;
  overflow: hidden;
  z-index: 99;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.4);
}
.react-contextmenu .react-contextmenu-item:hover {
  background: #cccccc;
}

.react-contextmenu-item:hover:not(.react-contextmenu-item--disabled) {
  background-color: #f1f1f1;
}

.react-contextmenu .react-contextmenu-item {
  font-size: 1.3rem;
  display: block;
  text-decoration: none;
  padding: 1rem 1.5rem;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
  position: relative;
  margin-bottom: 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
  outline: none;
}
.react-contextmenu-item span {
  margin-left: 1rem;
}
.react-contextmenu-item .copy {
  fill: skyblue;
  height: 1.5rem;
  width: 1.5rem;
}
.react-contextmenu-item .delete {
  fill: red;
  height: 1.5rem;
  width: 1.5rem;
}
.react-contextmenu-item .send {
  fill: blue;
  height: 1.5rem;
  width: 1.5rem;
}
.react-contextmenu-item .share {
  fill: green;
  height: 1.5rem;
  width: 1.5rem;
}
.react-contextmenu-item .watchlist {
  fill: rebeccapurple;
  height: 1.5rem;
  width: 1.5rem;
}
/* ============================================Context Menu CSS End============================================ */

.Toastify__toast-body {
  font-size: 1.5rem;
}

/* .Toastify__toast--success {
	background-color: var(--primary-color) !important;
}

.Toastify__toast--error {
	background-color: var(--red) !important;
} */

.gm-style .gm-style-iw-c {
  /* background-color: rgba(255, 255, 255, .8) !important; */
  background-color: transparent !important;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));
  padding: 0 !important;
}
.gm-style .gm-style-iw-tc::after {
  background-color: rgba(255, 255, 255, 0.9) !important;
  top: 0 !important;
}
.gm-style .gm-style-iw-d {
  overflow: auto !important;
}
.gm-style .gm-style-iw-c button {
  height: 2rem !important;
  width: 2rem !important;
  background-color: var(--primary-color) !important;
  border-radius: 50%;
  color: var(--white);
  right: 0.5rem !important;
  top: 0.5rem !important;
  transition: all 0.4s;
}
.gm-ui-hover-effect > span {
  background-color: var(--white) !important;
  width: 1rem !important;
  height: 1rem !important;
  margin: 0.5rem !important;
}
