.upper_content {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 25% calc(75% - 2rem);
  gap: 2rem;
  overflow: hidden;
}
/* .right_table {
  width: 70%;
} */
.statCards,
.statCard2 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--white);
  border-radius: 2rem;
}

.statCard2 {
  padding: 2rem;
  height: 35rem;
  overflow: auto;
}

.statCard_1,
.statCard_2 {
  background-color: #f1f1f1;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem;
}
.group {
  display: flex;
  align-items: flex-end;
}
.statCard_1_title,
.statCard_2_title {
  font-size: 1.3rem;
  font-weight: 700;
  margin-left: 0.5rem;
  color: var(--grey);
}

.statCard_1_stat {
  font-family: 'Poppins', sans-serif;
  font-size: 4rem;
  font-weight: 400;
  color: #ddaa6f;
}

.statCard_2_stat {
  font-family: 'Poppins', sans-serif;
  font-size: 4rem;
  font-weight: 400;
  line-height: 1;
  color: #55b7e5;
}
.statCard_2_percent {
  font-family: 'Poppins', sans-serif;
  background-color: var(--white);
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.icon {
  height: 1.5rem;
  width: 1.5rem;
}
.contents {
  margin-top: 4rem;
  display: flex;
  gap: 2rem;
  align-items: stretch;
}

.contents_chart {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.contents_title {
  font-size: 1.5rem;
}

.contents_select_boxes {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.contents_select_box {
  background-color: var(--white);
  border-radius: 2rem;
  padding-right: 1.5rem;
}
.contents_select_box select {
  border: none;
  background-color: transparent;
  font-family: 'Poppins', sans-serif;
  font-size: 1.3rem;
  padding: 0.8rem 1.5rem;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.charts {
  background-color: var(--white);
  /* height: 100%; */
  height: 40vh;
  overflow: auto;
  border-radius: 2rem;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.chart {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.chart_bar {
  height: 2rem;
  min-width: fit-content;
  padding: 0 1rem;
  background-color: var(--primary-color);
  color: var(--light-grey);
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  display: flex;
  align-items: center;
}

.chartBar_title {
  font-size: 1.2rem;
  font-weight: 600;
}

.map_container {
  height: 500px;
  border-radius: 3rem;
  /* margin-top: 30px; */
}

.map_integration {
  display: inline;
}

.map {
  margin-top: 4rem;
}

.infoWindow {
  font-size: 1.3rem;
  font-weight: 500;
  font-family: 'Raleway', sans-serif;
  padding-top: 2.5rem;
  padding-right: 2.5rem;
  padding-left: 1.5rem;
  padding-bottom: 2rem;
  text-align: center;
}

.infoWindow_title {
  font-size: 1.5rem;
}

.infoWindow_ad {
  margin-top: 1rem;
  margin-top: 2rem;
  width: 100%;
  display: flex;
  gap: 2rem;
  align-items: center;
  flex-direction: column;
}

.infoWindow_ad img {
  height: 3rem;
}

.infoWindow_ad_count p {
  width: 22rem;
}

.infoWindow_ad_count h6 {
  font-size: 1.5rem;
}

.filterToggleContainer {
  display: flex;
  align-items: center;
  padding-bottom: 1.5rem;
}

.rightOnlyBorder {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.leftOnlyBorder {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.filterToggle {
  /* border: solid 1px rgb(197, 197, 197); */
  padding: 1px 8px;
  width: 5rem;
  border-radius: 2rem;
  text-align: center;
  transition: all 0.4s;
}

.filterToggleActive {
  border: solid 1px var(--primary-color);
  background-color: var(--primary-color);
  border-radius: 2rem;
  color: var(--white);
  padding: 1px 8px;
  width: 5rem;
  text-align: center;
  transition: all 0.4s;
}

.dateRangeContainer {
  align-self: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  column-gap: 10px;
}

.dateRangeContainer > input {
  border: 1px solid rgb(214, 214, 214);
  outline: none;
  padding: 2px 1rem;
  border-radius: 4px;
}

.dateRangeContainer > button {
  border: 1px solid var(--primary-color);
  border-radius: 2rem;
  color: var(--primary-color);
  outline: none;
  padding: 0.8rem 1.5rem;
  background-color: var(--white);
  cursor: pointer;
  transition: all 0.4s;
  align-self: center;
}
.dateRangeContainer > button:hover {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  color: var(--white);
}

.navigation {
  display: flex;
  align-items: center;
  gap: 2rem;
  color: var(--grey);
  font-weight: 600;
  cursor: pointer;
}

.active {
  display: flex;
  align-items: center;
  gap: 2rem;
  color: var(--primary-color);
  font-weight: 600;
  cursor: pointer;
}

.navigate {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.4rem;
}

.icons {
  height: 2rem;
  width: 2rem;
  background: var(--white);
  border-radius: 50%;
  padding: 0.3rem;
}

.blue {
  height: 2rem;
  width: 2rem;
  background: white !important;
  padding: 0.3rem;
  border-radius: 50%;
  fill: var(--primary-color);
}

.toggle {
  display: flex;
  align-items: center;
  background-color: var(--white);
  border-radius: 20px;
  height: fit-content;
  font-size: 1.2rem;
  cursor: pointer;
}

.ad_summary_title {
  font-size: 1.5rem;
  font-weight: 700;
}

.ad_summary {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  justify-content: space-between;
  width: 23vw;
}

.contents_titles {
  font-size: 1.5rem;
  margin-right: 2rem;
}

.select {
  border: none;
  border-radius: 2rem;
  padding: 0.3rem;
  max-width: 8vw;
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
}

select {
  border: none;
  outline: none;
}

select option:checked {
  background-color: lightgray;
  color: #000;
}

.model {
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
}

.btn {
  border: 1px solid var(--primary-color);
  border-radius: 20px;
  color: var(--primary-color);
  outline: none;
  padding: 2px 1rem;
  background-color: var(--white);
}

.date_picker {
  border: none;
  outline: none;
  border-radius: 2rem;
  padding: 0.8rem 1.5rem;
  background-color: var(--white);
  animation: fadeIn 1s linear 1 forwards;
}
.date_picker input {
  border: none;
  outline: none;
  font-size: 1.3rem;
  background-color: transparent;
  font-family: 'Poppins', sans-serif;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1200px) {
  .upper_content {
    flex-direction: column;
  }

  .right_table {
    width: 100%;
  }

  .ad_summary {
    width: 100%;
  }

  .statCard_1,
  .statCard_2 {
    width: 100%;
  }

  .statCards,
  .statCard2 {
    width: 100%;
  }

  .statCard_2_percent {
    width: 9vw;
  }
}

@media (max-width: 1025px) {
  .group {
    flex-direction: row;
  }

  .statCard_2_stat {
    font-size: 4rem;
  }
}

@media (max-width: 426px) {
  .navigation {
    justify-content: center;
  }

  .statCard_2_percent {
    width: 15vw;
  }
}

@media (max-width: 570px) {
  .filterToggleContainer {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .dateRangeContainer {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }
}
