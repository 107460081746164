.container {
  display: grid;
  grid-template-columns: 30rem repeat(2, 1fr) 30rem;
  grid-template-rows: 10rem 1fr 10rem;
  min-height: 100vh;
  background-color: var(--white);
}
.input_container {
  grid-column: 2/3;
  grid-row: 2/3;
  margin-right: 10rem;
  display: flex;
  gap: 3rem;
  flex-direction: column;
  justify-content: space-between;
}

.logo {
  align-self: flex-start;
  height: 5rem;
}
.container_content {
  display: flex;
  gap: 5rem;
  flex-direction: column;
}
.container_heading {
  font-size: 3rem;
  font-weight: 600;
  color: var(--grey);
}
.container_heading span {
  display: block;
  font-size: 1.5rem;
}
.container_form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.container_copyright {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--grey);
}
.container_copyright a {
  color: currentColor;
}

/* carousel styles */
.carousel_container {
  grid-column: 3/4;
  grid-row: 2/3;
  background-color: var(--bg-color);
  border-radius: 3rem;
  /* display: flex;
  align-items: center; */
}
.carousel_item {
  height: calc(100vh - 20rem);
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; */
  padding: 5rem 5rem;
}
.carousel_texts {
  position: absolute;
  left: 5rem;
  /* margin-left: 5rem; */
  /* align-self: flex-start; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.carousel_title {
  font-size: 2.5rem;
  font-weight: 800;
  color: var(--primary-color);
}
.carousel_subtitle {
  text-align: left;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--grey);
  width: 70%;
}
@media only screen and (max-width: 1600px) {
  .container {
    grid-template-columns: 20rem repeat(2, 1fr) 20rem;
  }
}
@media only screen and (max-width: 1440px) {
  .container {
    grid-template-columns: 10rem repeat(2, 1fr) 10rem;
  }
}
@media only screen and (max-width: 1200px) {
  .container {
    grid-template-columns: 5rem repeat(2, 1fr) 5rem;
  }
  .input_container {
    margin-right: 5rem;
  }
}
@media only screen and (max-width: 992px) {
  .container {
    grid-template-columns: 10rem 1fr 10rem;
  }
  .input_container {
    margin-right: 0;
  }
  .carousel_container {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .container {
    grid-template-columns: 5rem 1fr 5rem;
    grid-template-rows: 2rem 1fr 2rem;
  }
}
