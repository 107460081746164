.button,
.button_disabled {
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: 600;
  transition: all 0.4s;
  text-align: center;
}
.button {
  color: var(--primary-color);
  cursor: pointer;
}
.button_disabled {
  color: var(--light-grey);
  cursor: not-allowed;
}
.button:hover {
  transform: translate(0.2rem, -0.2rem);
}
