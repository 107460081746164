/* Questionaire Model css */
.modalcontainer {
  min-width: 103rem;
  min-height: 50rem;
  position: relative;
}
.noContent {
  min-width: '100%';
  min-height: 38rem;
}
.buttonsWrapper {
  position: absolute;
  bottom: 3rem;
  right: 1rem;
}
.buttons {
  display: flex;
  justify-content: flex-end;
}
.container {
  padding: 3rem 0;
}
.answerCard {
  width: 100rem;
  padding: 1rem 2rem;
  background: #ffffff;
  border-radius: 1.5rem;
  margin-bottom: 1rem;
}
.question {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #000000;
}

.answer {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #000000;
  margin-top: 0.5rem;
}

.response {
  color: #0f407a;
}
.message {
  width: 100%;
  min-height: 38rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.message div {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.1rem;
  color: #0f407a;
}
