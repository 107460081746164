.advertise_container {
  display: flex;
  column-gap: 2rem;
  padding: 1rem;
  height: 30vh;
}

.content {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.content_link {
  align-self: flex-end;
  text-decoration: underline;
  color: var(--primary-color);
  font-size: 1.3rem;
  font-weight: 600;
  transition: all 0.4s;
}

.content_link:hover {
  transform: scale(1.03);
}

.content_card {
  background-color: var(--white);
  border-radius: 2rem;
  padding: 2rem 2.5rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.line {
  opacity: 0.2;
  padding: 0;
}

.content_card_images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
}

.content_card_image {
  width: 12vw;
  /* height: 3vh; */
  border-radius: 0.5rem;
}

.content_card_image_last {
  position: relative;
}

.content_card_number {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 2rem;
}

.content_card_texts {
  line-height: 1.4;
  color: var(--grey);
  width: 100%;
}

.content_card_heading {
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--primary-color);
}

.content_card_sub_heading {
  font-size: 1.2rem;
  font-weight: 500;
  font-style: italic;
  margin-bottom: 0.5rem;

  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.content_card_desc {
  font-size: 1.2rem;
  font-weight: 600;
  width: inherit;
}

.content_card_actions_stats {
  display: flex;
  gap: 1rem;
}

.content_card_stats {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;
  height: 6rem;
}

.content_card_stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.content_card_stat_number {
  font-weight: 600;
}

.content_card_stat_bar {
  min-height: 0.1rem;
  width: 3rem;
  background-color: var(--primary-color);
}

.content_card_stat_date {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--grey);
}

.add_new_btn {
  color: #0f407a;
  border: 1px solid #0f407a;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
  transition: 0.4s all;
  cursor: pointer;
  width: 12rem;
  padding: 0.2rem;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.add_new_btn:hover {
  color: #ffffff;
  border: 1px solid #0f407a;
  background-color: #0f407a;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
  transition: 0.4s all;
  cursor: pointer;
  width: 12rem;
  padding: 0.2rem;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.delete_image {
  position: absolute;
  top: 4;
  right: 0;
  padding: 4px;
  height: 30px;
  width: 30px;
  font-weight: bolder;
  color: white;
  fill: red;
  text-align: center;
  transition: all 0.4s;
}
.delete_image:hover {
  cursor: pointer;
  position: absolute;
  top: 4;
  right: 0;
  padding: 4px;
  height: 30px;
  width: 30px;
  font-weight: bolder;
  color: white;
  fill: red;
  text-align: center;
  transform: scale(1.2);
  transition: all 0.4s;
}

.navigation {
  display: flex;
  align-items: center;
  gap: 2rem;
  color: #5e5e5e;
  font-weight: 600;
  cursor: pointer;
}

.navigate {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.4rem;
  text-decoration: none;
  color: #5e5e5e;
}
.active {
  color: #0f407a;
  font-weight: 600;
  cursor: pointer;
}

.icons {
  height: 2rem;
  width: 2rem;
  background: white;
  border-radius: 50%;
  padding: 0.3rem;
}

.blue {
  height: 2rem;
  width: 2rem;
  background: white;
  padding: 0.3rem;
  border-radius: 50%;
  fill: #0f407a;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}

.card {
  height: fit-content;
  background-color: #f1f1f1;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 0.5rem;
}

.chips {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.chip {
  height: fit-content;
  width: fit-content;
  background-color: var(--white);
  border-radius: 2rem;
  border: 1px solid var(--light-grey);
  display: flex;
  align-items: center;
  gap: 0.3rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.chip p {
  text-align: center;
}

.image_text {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  color: black;
}

.dotGreen {
  width: 0.5rem;
  height: 0.5rem;
  background-color: green;
  border-radius: 50%;
}

.dotRed {
  width: 0.5rem;
  height: 0.5rem;
  background-color: red;
  border-radius: 50%;
}

.volumeText {
  font-size: inherit;
}

.volumeValue {
  font-family: 'Poppins', sans-serif;
}

.navigation_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toggle {
  align-self: center;
  display: flex;
  align-items: center;
  background-color: var(--white);
  border-radius: 20px;
  /* height: fit-content; */
  font-size: 1.2rem;
  cursor: pointer;
}
.rightOnlyBorder {
  background-color: transparent;
  border: none;
  font-family: inherit;
  border-radius: 2rem;
  cursor: pointer;
}
.filterToggle {
  text-align: center;
  padding: 0.8rem 1.5rem;
  transition: all 0.4s;
}

.filterToggleActive {
  background-color: #0f407a;
  padding: 1rem 2rem;
  color: white;
  text-align: center;
  transition: all 0.4s;
}

.table__buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.card_header {
  display: flex;
}

.table__button {
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background-color: red;
  border-radius: 50%;
  cursor: pointer;
  padding: 0.5rem;
}

.view_rules_btn {
  outline: none;
  border: none;
  background-color: var(--white);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 2rem;
  width: 6vw;
  padding: 0.5rem;
  cursor: pointer;
}

.model_container {
  display: flex;
  flex-direction: column;
  background-color: #f2f8ff;
  gap: 2rem;
  margin-bottom: 2rem;
}

.model_card_container {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  height: fit-content;
  width: 100%;
  border-radius: 2rem;
}

.model_card_container hr {
  opacity: 0.2;
}

.model_card_upper_content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 2rem;
}

.model_card_lower_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
}

.upper_content_name {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.upper_content_name p {
  font-size: 1.2rem;
}

.upper_content_name h3 {
  display: flex;
  font-size: 1rem;
  /* background: var(--light-grey); */
  /* border-radius: 2rem;
  padding: 0.1rem 0.5rem 0.1rem 0.5rem; */
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
}

.lower_content_chips {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.lower_content_chip1 {
  background-color: var(--primary-color);
  border-radius: 2rem;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
  color: var(--white);
  text-transform: capitalize;
}

.lower_content_chip2 {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  background-color: #4ed28b;
  border-radius: 2rem;
  padding: 0.2rem 1rem;
  color: var(--white);
  text-transform: capitalize;
}

.wide_chips_container {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid rgba(12, 8, 8, 0.103);
  border-radius: 2rem;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
}

.wide_chip {
  display: flex;
  background-color: #e9f3fa;
  color: #0f407a;
  justify-content: center;
  border-radius: 2rem;
  padding: 0.1rem 1.5rem 0.1rem 1.5rem;
}

@media (max-width: 1400px) {
  .view_rules_btn {
    width: max-content;
  }
}

@media (max-width: 1024px) {
  .content_card_image {
    width: 100%;
    height: 3vh;
  }
}

@media (max-width: 566px) {
  .card_header {
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: end;
  }
}

@media (max-width: 426px) {
  .navigation_content {
    flex-direction: column;
    gap: 2rem;
  }

  .card {
    width: 100%;
  }
}
