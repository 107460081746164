.container button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.rating_icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--primary-color);
}
