.button,
.button_danger {
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1.3rem 2.5rem;
  border-radius: 3rem;
  background-color: transparent;
  transition: all 0.4s;

  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.button {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  cursor: pointer;
}
.button:disabled,
.button_danger:disabled {
  color: var(--light-grey);
  border: 1px solid var(--light-grey);
  cursor: not-allowed;
}
.button_danger {
  color: var(--red);
  border: 1px solid var(--red);
  cursor: pointer;
}

.button_icon {
  height: 1.7rem;
  width: 1.7rem;
  transition: all 0.4s;
}
.button .button_icon {
  fill: var(--primary-color);
}
.button:disabled .button_icon,
.button_danger:disabled .button_icon {
  fill: var(--light-grey);
}
.button_danger .button_icon {
  fill: var(--red);
}

.button:hover {
  background-color: var(--primary-color);
  color: var(--white);
}
.button:disabled:hover,
.button_danger:disabled:hover {
  background-color: var(--light-grey);
  color: var(--white);
}
.button:hover .button_icon,
.button_danger:hover .button_icon {
  fill: var(--white);
}

.button_danger:hover {
  background-color: var(--red);
  color: var(--white);
}
