.inputBox {
	margin: .5rem 0;
	background-color: transparent;
	border: 1px solid #d0d0d0;
	border-radius: 1rem;
	display: flex;
	align-items: center;
	padding: 0 1rem;
	transition: all .4s;
}

.inputBox:hover,
.inputBox:focus {
	border: 1px solid #000;
}
.textInput__input {
	padding: 1rem 0;
	width: 100%;
	border: none;

	outline: none;
	font-family: inherit;
	font-size: 1.2rem;
	color: #000;
}
.errorText {
	color: red;
}

.textInput__label {
	font-size: 1.2rem;
	font-weight: 600;
	color: var(--grey);
}
.inputBox__icon {
	height: 2rem;
	width: 2rem;
	fill: #000;
	margin-right: 1rem;
}

@media screen and (max-height: 700px) {
	.inputBox {
		margin-top: 0;
		height: 31px;
		margin-bottom: 0;
	}

	input {
		height: 30px;
		background-color: transparent;
	}
}
